import ContainerMain from "src/pages/common/ContainerMain"
import ImageZoomEffect from "pages/common/ImageZoomEffect"
import new1 from "images/Product/Campaign/product-3-end-1.png"
import new2 from "images/Product/Campaign/product-3-end-2.png"
import new3 from "images/Product/Campaign/product3_3.png"
import { useFormatter } from "helpers/i18n"

import "./New.scss"

export default function New() {
  const { __ } = useFormatter()
  return (
    <div className="muaHKANNrk">
      <ContainerMain>
        <div className="iuOmyiOHTj">
          <div className="fkAbIuQptr">
            <div className="WHhnVXjdkh">
              {__({
                defaultMessage:
                  " With our AI-Driven Shopper Segmentation and Persona Analytics, OnPoint enables brands to increase sales and enhance brand loyalty, customer engagement and communication.",
              })}
            </div>
            <div className="RqSwAGZvZU">
              <div className="aFbRPPhZNG">
                <div className="ZEntAfMFJe">
                  <ImageZoomEffect className="HuMwlHqPhh" alt="" src={new1} />
                </div>
                <div className="ipNYWGRRcn">
                  {__({ defaultMessage: "Increase marketing efficiency" })}
                </div>
              </div>
              <div className="aFbRPPhZNG">
                <div className="ZEntAfMFJe">
                  <ImageZoomEffect className="HuMwlHqPhh" alt="" src={new2} />
                </div>
                <div className="ipNYWGRRcn">
                  {__({
                    defaultMessage: "Provide better customer targeting",
                  })}
                </div>
              </div>
              <div className="aFbRPPhZNG">
                <div className="ZEntAfMFJe">
                  <ImageZoomEffect className="HuMwlHqPhh" alt="" src={new3} />
                </div>
                <div className="ipNYWGRRcn">
                  {__({
                    defaultMessage:
                      "Help brands to find new market opportunities",
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </ContainerMain>
    </div>
  )
}
